'use strict'
###*
 # @ngdoc directive
 # @name mundoComponents.directive:mundoTeaser
 # @restrict EA
 # @element

 # @description

 # @example
   <example module="mundoComponents">
     <file name="index.html">
      <mundo-teaser></mundo-teaser>v
     </file>
   </example>

###
class MundoTeaser
  ### @ngInject ###
  constructor: ->
    return {
      restrict: 'AE'
      scope: {}
      template: '<ng-include src="templateUrl"/>'
      replace: false
      bindToController: {
        entity:'='
        type:'='
        templateOptions:'='
      }
      controllerAs: 'teaser'
      controller: ->
      link: (scope, element, attrs) ->
        ###jshint unused:false ###
        ###eslint "no-unused-vars": [2, {"args": "none"}]###

##      default template folder
        templateFolder = 'mundo-components/mundo-teaser/views'
        if scope.teaser.templateOptions?.folder
          templateFolder = scope.teaser.templateOptions.folder

        scope.templateUrl = "#{templateFolder}/#{scope.teaser.type}.tpl.html"
    }

angular
  .module 'mundoComponents'
  .directive 'mundoTeaser', MundoTeaser
